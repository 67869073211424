import {INTAKE_UPDATE_CANVAS_REF, INTAKE_UPDATE_INTAKE_ID} from './actionTypes';

const initialState = {
    identifier: '',
    canvas: '',
};

function intake(state = initialState, action) {
    switch (action.type) {
        case INTAKE_UPDATE_INTAKE_ID: {
            const {identifier} = action.payload;

            return {...state, identifier};
        }
        case INTAKE_UPDATE_CANVAS_REF: {
            const {canvas} = action.payload;

            return {...state, canvas};
        }
        default: {
            return {...state};
        }
    }
}

export default intake;
