import {exportSchematics} from './saveSchematics';
import store from '../store/store';

export function submitIntakeForm(values) {
    exportSchematics().then(result => {
        result.json().then(data => {
            const {intake} = data;

            updateIntake(intake, values).then(result => {
                const {uuid} = result;

                window.location.replace(`/intake/${uuid}/complete`);
            });
        });
    });
}

export function updateIntake(identifier, values) {
    return fetch(`/api/security-intake/${identifier}/details`, {
        method: 'post',
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        .catch(error => console.warn(error));
}

export function submitIntakeExtraInfoForm(values) {
    const {intake: {identifier}} = store.getState();

    updateIntakeExtraInfo(identifier, values).then(result => {
        const {securityIntake: {uuid}} = result;

        window.location.replace(`/intake/${uuid}/additional-info/complete`);
    });
}

export function updateIntakeExtraInfo(identifier, values) {
    return fetch(`/api/security-intake/${identifier}/additional-info`, {
        method: 'post',
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        .catch(error => console.warn(error));
}
