import React from 'react';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';

import connect from "react-redux/es/connect/connect";
import renderField from '../../components/blocks/renderField';
import renderError from '../../components/blocks/renderError';
import validate from '../validate';

class SimpleContactInformation extends React.Component {

    render() {
        return (
            <>
                <div className="form__category">
                    <h2 className="h2 card__title">Uw gegevens</h2>

                    <div className="form__group">
                        <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-gender-men" name="gender" value="m" />
                                <div className="form__radio" />
                                <label htmlFor="radio-gender-men" className="form__label">Dhr.</label>
                            </div>

                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-gender-women" name="gender" value="f" />
                                <div className="form__radio" />
                                <label htmlFor="radio-gender-women" className="form__label">Mw.</label>
                            </div>

                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-gender-none" name="gender" value="o" />
                                <div className="form__radio" />
                                <label htmlFor="radio-gender-none" className="form__label">Geen van beide</label>
                            </div>
                        </div>
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="initials" type="text" component={renderField} label="Voornaam"/>
                        <Field name="lastname" type="text" component={renderField} label="Achternaam"/>
                    </div>

                    <div className="form__group">
                        <Field name="company" type="text" component={renderField} label="Bedrijfsnaam"/>
                    </div>
                    <div className="form__group">
                        <Field name="contact" type="text" component={renderField} label="Contactpersoon"/>
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="phone" type="text" component={renderField} label="Telefoonnummer"/>
                        <Field name="email" type="text" component={renderField} label="E-mail"/>
                        <div className="form__input-wrapper" />
                        <div className="form__input-wrapper" style={{paddingTop: '4px'}}>
                            <Field name="email" component={renderError}/>
                        </div>
                    </div>

                    <div className="form__group">
                        <div className="form__input-wrapper">
                            <div className="form__checkbox-wrapper w-100">
                                <Field name="agreedPrivacy" id="agreedPrivacy" component="input" type="checkbox" value="yes"/>
                                <div className="form__checkbox" />
                                <label htmlFor="agreedPrivacy" className="form__label">Ik ga akkoord met de <a
                                    href="https://omniaconnect.nl/wp-content/uploads/2023/02/Omnia-Connect-Algemene-voorwaarden.pdf"
                                    target="_blank" className="form__label--colored">algemene leveringsvoorwaarden</a>.</label>
                            </div>
                            <Field name="agreedPrivacy" component={renderError}/>

                            <div className="form__checkbox-wrapper w-100">
                                <Field name="agreedInstallation" id="agreedInstallation" component="input" type="checkbox" value="yes"/>
                                <div className="form__checkbox" />
                                <label htmlFor="agreedInstallation" className="form__label">Ik ga akkoord met de <a
                                    href="https://omniaconnect.nl/wp-content/uploads/2023/02/OmniaConnect_Installatievoorwaarden_DEF.pdf"
                                    target="_blank" className="form__label--colored">installatie voorwaarden</a></label>
                            </div>
                            <Field name="agreedInstallation" component={renderError}/>
                        </div>
                    </div>

                </div>
            </>

        );
    }
}

SimpleContactInformation = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(SimpleContactInformation);

const selector = formValueSelector('wizard');
SimpleContactInformation = connect(
    state => ({
        hasInvoiceAddress: selector(state, 'differentInvoiceAddress'),
        discount: selector(state, 'discount'),
        formData: getFormValues('wizard')(state)
    }),
)(SimpleContactInformation);

export default SimpleContactInformation;
