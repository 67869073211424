import React from 'react';
import {arrayPop, arrayPush, Field, FieldArray, formValues} from "redux-form";
import renderField, {renderNumberField, renderProductField} from "./blocks/renderField";
import {renderIcon} from "./blocks/renderIcon";
import {renderPrice} from "./blocks/renderPrice";
import MobilePhoneInput from '../order/mobilePhone/MobilePhoneInput';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ReadMoreDescription from './ReadMoreDescription';


const renderNumberSelector = (product) => <div className="number-selector">
    <Field id={product.id} className="specifications__number-input" name={`product_${product.id}`} type={'number'} component={renderNumberField}/>
</div>

const renderCheckboxSelector = (product, changeHandler) => <div className="switch">
    <Field id={product.id} name={`product_${product.id}`} type={'checkbox'} component={renderProductField} value={'1'} onChange={changeHandler}/>
    <label htmlFor={product.id}/>
</div>


const renderInputType = (product, changeHandler) => {
    if (product.is_multiple) {
        return renderNumberSelector(product);
    }
    return renderCheckboxSelector(product, changeHandler);
}

const renderPriceOnce = (product) => {
    if (product.price_once > 0) {
        return <span className="lightswitch__label">Eenmalig &euro; {renderPrice(product.price_once)} {product.is_multiple && 'p/s'}</span>
    }

    return null;
}

function renderPriceMonthly(product) {
    if (product.price > 0) {
        return <span className="lightswitch__label">+{renderPrice(product.price)} p/m</span>;
    }

    return null;
}

const renderProductPricing = (product, changeHandler) => {
    // Product is included at least once and product cannot be selected multiple times
    if (product.amount_included_in_package > 0 && false === product.is_multiple) {
        return <div className="specifications__interaction">
            <span className="specifications__label">Inbegrepen</span>
        </div>
    }

    return <div className="specifications__interaction">
        {product.amount_included_in_package > 0 && <span className="specifications__label">{product.amount_included_in_package} inbegrepen</span>}

        <div className="form-group">
            {renderInputType(product, changeHandler)}
        </div>

        {renderPriceMonthly(product)}
        {renderPriceOnce(product)}

    </div>
}

class Product extends React.Component {
    static propTypes = {
        popArray: PropTypes.func.isRequired,
        pushArray: PropTypes.func.isRequired
    }

    componentDidUpdate(prevProps) {
        const {name, product} = this.props;
        const amount = parseInt(this.props.value || '0');
        const prevAmount = parseInt(prevProps.value || '0');

        if (product.is_mobile && amount !== prevAmount) {
            const diffAmount = amount - prevAmount;

            if (diffAmount < 0) {
                // if numbers are removed
                for(let i = 0; i < Math.abs(diffAmount); i++) {
                    this.props.popArray('wizard', `${name}Numbers`);
                }
            } else if (diffAmount > 0) {
                // if numbers are added
                for(let i = 0; i < diffAmount; i++) {
                    this.props.pushArray('wizard', `${name}Numbers`, {});
                }
            }
        }
    }

    renderPhysicalProduct = () => {
        const {product, changeHandler, name, hasMobileSubscriptionProducts} = this.props;
        const descriptionElements = product.description.split('#');

        return <div className="specifications__item-wrapper product-item">
            <div className="product-header">
                <h4 className="h4 specifications__title">{product.name}</h4>
                <div className="flex row between items-start">
                    <div className="product-image-container">
                        {renderIcon(product.image)}
                    </div>
                    {renderProductPricing(product, changeHandler)}
                </div>

            </div>
            <div className="specifications__item specifications-text">
                <div>
                    {descriptionElements.length === 1 && <ReadMoreDescription description={product.description} />}

                    {descriptionElements.length > 1 && <ul className="specifications__advantages" style={{'listStyleType': 'none'}}>
                        {product.description.split('#').map((item, index) => <li key={index} className="specifications__advantage">{item}</li>)}
                    </ul>}
                </div>
            </div>
            {hasMobileSubscriptionProducts && <div className="specifications__item col">
                <p>Vul hier de nummers in die u wilt behouden. Vult u geen bestaande telefoonnummers in, dan ontvangt u nieuwe nummers.</p>
                <FieldArray name={`${name}Numbers`} component={MobilePhoneInput} />
            </div>}
        </div>;
    };

    renderProduct = () => {
        const {product, changeHandler, name, hasMobileSubscriptionProducts} = this.props;
        const descriptionElements = product.description.split('#');

        return (
            <div className="specifications__item-wrapper">
                <div className="specifications__item">
                    <div className="specifications__image specifications__product-image">
                        {renderIcon(product.image)}
                    </div>
                    <div className="specifications__wrapper">
                        <div className="specifications__info">
                            <h4 className="h4 specifications__title">{product.name}</h4>

                            {descriptionElements.length === 1 && <ReadMoreDescription description={product.description} />}

                            {descriptionElements.length > 1 && <ul className="specifications__advantages" style={{'listStyleType': 'none'}}>
                                {product.description.split('#').map((item, index) => <li key={index} className="specifications__advantage">{item}</li>)}
                            </ul>}
                        </div>

                        <div className="specifications__interaction">
                            {renderProductPricing(product, changeHandler)}
                        </div>

                    </div>

                </div>
                {hasMobileSubscriptionProducts && <div className="specifications__item col">
                    <p>Vul hier de nummers in die u wilt behouden. Vult u geen bestaande telefoonnummers in, dan ontvangt u nieuwe nummers.</p>
                    <FieldArray name={`${name}Numbers`} component={MobilePhoneInput} />
                </div>}
            </div>
        );
    };

    render() {
        const {product: {is_physical}} = this.props;

        if (is_physical) {
            return this.renderPhysicalProduct();
        }

        return this.renderProduct();
    }

}

const mapDispatchToProps = {
    pushArray: arrayPush,
    popArray: arrayPop,
};

export default connect(null, mapDispatchToProps)(formValues(props => ({value: props.name}))(Product));
