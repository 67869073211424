export const validate = values => {
    const errors = {};

    if (!values.cameras) {
        errors.cameras = 'Dit veld is verplicht';
    }

    if (!values.nvrAmount) {
        errors.nvrAmount = 'Dit veld is verplicht';
    }

    if (values.nvrAmount) {
        if (!values.nvrLocation) {
            errors.nvrLocation = 'Dit veld is verplicht';
        }
    }

    if (!values.ceilingType) {
        errors.ceilingType = 'Dit veld is verplicht';
    }

    if (values.needScreen) {
        if (!values.screenLocation) {
            errors.screenLocation = 'Dit veld is verplicht';
        }

        if (values.needVgaCable) {
            if (!values.vgaCable || values.vgaCable <= 0) {
                errors.screenLocation = 'Dit veld is verplicht en moet > 0 zijn';
            }
        }
    }
    
    values.cameraTypes.forEach((type, index) => {
        if (type) {
            if (!type.amount || type.amount <= 0) {
                if (!errors.cameraTypes) {
                    errors.cameraTypes = [];
                }

                if (!errors.cameraTypes[index]) {
                    errors.cameraTypes[index] = {};
                }

                errors.cameraTypes[index].amount = 'Dit veld is verplicht';
            }

            if (!type.type) {
                if (!errors.cameraTypes) {
                    errors.cameraTypes = [];
                }

                if (!errors.cameraTypes[index]) {
                    errors.cameraTypes[index] = {};
                }

                errors.cameraTypes[index].type = 'Dit veld is verplicht';
            }
        }
    });

    return errors;
}
