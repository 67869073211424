import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Excalidraw, exportToSvg, exportToBlob} from '@excalidraw/excalidraw';
import {uploadSchematics} from '../helpers/saveSchematics';
import IntakeImages from './intakeImages';
import InstallationImages from '../../order/installation/InstallationImages';
import {Field, reduxForm, submit} from 'redux-form';
import {updateCanvasRef} from '../store/actions';


class Schematics extends React.Component {
    static propTypes = {
        updateCanvasRef: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.excalidrawRef = React.createRef();

        this.initialState = {
            elements: [],
            appState: {
                currentItemBackgroundColor: "#ffffff",
                currentItemFillStyle: "solid",
                activeTool: {
                    locked: true,
                    type: "rectangle",
                },
            },
        };

        this.state = {
            isFullScreen: false,
        };
    }

    componentDidMount() {
        this.props.updateCanvasRef(this.excalidrawRef);
    }

    toggleFullScreen = () => {
        this.setState({isFullScreen: !this.state.isFullScreen});
    }

    render() {
        const {identifier} = this.props;
        const {isFullScreen} = this.state;

        return <div className={`schematics-container ${isFullScreen ? 'fullscreen' : 'normal'}`}>
            <div className="schematics-canvas-container p-2 mb-3" style={{marginBottom: '10px'}}>
                <div className="schematics-button-container">
                    <button type="button" className="btn btn-success bordered" onClick={this.toggleFullScreen}>
                        {!isFullScreen && <><i className="fas fa-expand-arrows-alt" /><span> Volledig Scherm</span></>}
                        {isFullScreen && <><i className="fas compress-arrows-alt" />
                        <i className="fas fa-compress"></i><span> Volledig Scherm Verlaten</span></>}
                    </button>
                </div>
                <div className="border gray">
                    <Excalidraw
                        initialData={this.initialState}
                        ref={this.excalidrawRef}
                        zenModeEnabled={true}
                    />
                </div>
            </div>
            <div>
                <p>
                    Upload Foto's:
                </p>
                <Field name="intakeImages" component={props => {
                    return <IntakeImages intakeId={identifier} value={props.input.value} onChange={value => props.input.onChange(value)} />
                }} label="Foto's" />
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    const {identifier} = state.intake;

    return {identifier};
};

const mapDispatchToProps = dispatch => ({
    updateCanvasRef: canvas => dispatch(updateCanvasRef(canvas)),
});

export default reduxForm({
    form: 'intake',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(connect(mapStateToProps, mapDispatchToProps)(Schematics));
