import React from "react";
import {Field} from "redux-form";
import renderField from "../../components/blocks/renderField";
import CurrencyInput from "../CurrencyInput";

class Miscellaneous extends React.Component {

    render() {
        return <>
            <h2 className="h2 card__title">Vrije invoer</h2>

            <div className="form__group">
                <div className="form__input-wrapper">
                    <Field name="notes" component="textarea" className="form__input" placeholder="Opmerkingen"/>
                </div>
            </div>

            <div className="form__group form__group--half" style={{marginTop: '6rem'}}>
                <div className="form__input-wrapper">
                    <div className="form__search w-100">
                        <svg className="form__search-icon" width="11" height="22" viewBox="0 0 11 22">
                            <text id="_" data-name="€" transform="translate(0 18)" fill="#db0032"
                                  fontSize="19" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700">
                                <tspan x="0" y="0">€</tspan>
                            </text>
                        </svg>
                        <Field name="miscPriceMonthly" type="text" component={CurrencyInput} label="Prijs Maandelijks"/>
                    </div>
                </div>

                <div className="form__input-wrapper">
                    <div className="form__search w-100">
                        <svg className="form__search-icon" width="11" height="22" viewBox="0 0 11 22">
                            <text id="_" data-name="€" transform="translate(0 18)" fill="#db0032"
                                  fontSize="19" fontFamily="Ubuntu-Bold, Ubuntu" fontWeight="700">
                                <tspan x="0" y="0">€</tspan>
                            </text>
                        </svg>
                        <Field name="miscPriceOnce" type="text" component={CurrencyInput} label="Prijs Eenmalig"/>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default Miscellaneous;
