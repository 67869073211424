export function renderGender(gender) {
    if (gender === 'm') {
        return 'Dhr. ';
    }

    if (gender === 'f') {
        return 'Mw. ';
    }

    return '';
}
