import React, {Component} from 'react';
import {getFormValues, reduxForm} from 'redux-form';
import validate from './../validate';
import {connect} from "react-redux";
import ProductList from "../../components/ProductList";
import {generatePdfRequest, productsByType} from "../helpers";
import TelephoneNumbers from "../components/TelephoneNumbers";
import ContactInformation from "../components/ContactInformation";
import Confirmation from "../components/Confirmation";
import SimpleContactInformation from "../components/SimpleContactInformation";
import SimpleConfirmation from "../components/SimpleConfirmation";
import Miscellaneous from "../components/Miscellaneous";
import DownloadQuoteButton from '../components/DownloadQuoteButton';
import NextButton from '../components/NextButton';
import SendOrderConfirmationButton from "../components/SendOrderConfirmationButton";


class GenericOrderProcessStep extends Component {

    renderItem(step, item, formData, products, dispatch, index) {

        if (item.identifier === 'contact') {
            return <ContactInformation key={`step.id-${item.id || item.identifier}`}/>;
        }
        if (item.identifier === 'confirmation') {
            return <Confirmation key={`step.id-${item.id || item.identifier}`} />;
        }
        if (item.identifier === 'telephone') {
            return <TelephoneNumbers key={`step.id-${item.id || item.identifier}`} telephoneType={formData.telephoneType} withoutNoneOption={false}/>
        }
        if (item.identifier === 'simpleContact') {
            return <SimpleContactInformation key={`step.id-${item.id || item.identifier}`}/>;
        }
        if (item.identifier === 'simpleConfirmation') {
            return <SimpleConfirmation key={`step.id-${item.id || item.identifier}`} />;
        }
        if (item.identifier === 'miscellaneous') {
            return <Miscellaneous key={`step.id-${item.id || item.identifier}`} />;
        }

        return <ProductList key={step.id + '-' + item.id} title={item.name} formData={formData} dispatch={dispatch} products={productsByType(products, item.id)} description={item.description} index={index} />;
    }

    render() {
        const {handleSubmit, formData, products, dispatch, step, previousAction, lastStep} = this.props;

        if (!formData) {
            return null;
        }

        return (
            <form onSubmit={handleSubmit}>
                {step.items.map((item, index) => this.renderItem(step, item, formData, products, dispatch, index))}
                <div className="specifications__buttons">

                    {!previousAction && <><div className="btn btn--slim d-none" /><span /></>}

                    {previousAction && <div className="btn btn--slim btn-backbutton" onClick={previousAction}>
                        <div className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.455" height="16.733" viewBox="0 0 16.455 16.733">
                                <g id="Group_97" data-name="Group 97" transform="translate(0.5 0.811)">
                                    <path id="Path_1298" data-name="Path 1298"
                                          d="M1547.335,2309a1.558,1.558,0,0,0-.136.107c-1.948,1.948-5.35,5.46-7.3,7.409a1.476,1.476,0,0,0-.109.132l-1.055-1.074,5.67-5.782-.024-.048h-5.423l-7.081.048v-1.542l12.516-.048-5.727-5.61,1.115-1.113a.561.561,0,0,0,.08.134c1.95,1.954,5.382,5.269,7.335,7.22a1.671,1.671,0,0,0,.135.108Z"
                                          transform="translate(-1531.881 -2301.477)" strokeWidth="1" />
                                </g>
                            </svg>
                        </div>
                    </div>}

                    {lastStep && <SendOrderConfirmationButton />}
                    {lastStep && <DownloadQuoteButton />}

                    <NextButton lastStep={lastStep} />

                </div>
            </form>
        );
    }
}

GenericOrderProcessStep = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {
        telephoneType: 'none',
        phoneNumbers: [""],
        contractDetails: 'asap',
        paymentFrequency: 'monthly',
        zipcode: '',
        housenr: '',
        appendix: '',
        differentInvoiceAddress: false,
        ippin: true,
        alarm_over_ip: true,
        alarmRoom: false,
        alarmMaintenance: false,
        cameraStorage: false,
        cameraManagement: false,
        mobileUnlimited: 0,
        mobileUnlimitedNumbers: "",
        mobileUnlimited1g: 0,
        mobileUnlimited1gNumbers: "",
        mobileUnlimited10g: 0,
        mobileUnlimited10gNumbers: "",
        mobileUnlimited5g: 0,
        mobileUnlimited5gNumbers: "",
        partner: "",
        membershipNumber: "",
        pbxAmount: 0,
        telephone6863i: 0,
        telephone6865i: 0,
        telephoneN510: 0,
        telephoneS650H: 0,
        telephoneM685i: 0,
        discount: false,
        gender: 'm',
        cameraMiniDome: 0,
        cameraIrBullet: 0,
        cameraNvr: 0,
        cameraPackage: 0,
        providerName: '',
        providerNumber: '',
        multiTenant: true,
        miscPriceOnce: 0,
        miscPriceMonthly: 0,
        miscDescription: '',
        contractDuration: 36,
        sendCopyTo: '',
        orderProcess: '',
    },
    validate,
})(GenericOrderProcessStep);

GenericOrderProcessStep = connect(
    state => ({
        formData: getFormValues('wizard')(state)
    }),
)(GenericOrderProcessStep);

export default GenericOrderProcessStep;
