import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import intakeReducer from './intakeReducer';

const reducer = combineReducers({
    form: reduxFormReducer, // mounted under "form"
    intake: intakeReducer,
});
const store = (window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore)(reducer, applyMiddleware(thunk));

export default store;
