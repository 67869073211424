import {Field} from "redux-form";
import renderError from "../../components/blocks/renderError";
import FieldArray from "redux-form/lib/FieldArray";
import renderPhoneNumbers from "../../components/blocks/renderPhoneNumbers";
import renderField from "../../components/blocks/renderField";
import React from "react";

class TelephoneNumbers extends React.Component {

    render() {
        const {telephoneType, withoutNoneOption} = this.props;

        return <>
            <h2 className="h2 card__title">Telefoon</h2>
            <div className="accordion__wrapper">
                <div className="accordion accordion--active">
                    <div className="form-group">
                        <div className="accordion__head">
                            <div className="accordion__title">Type telefoonnummer</div>
                        </div>
                        <div className="accordion__body">
                            <div className="specifications__radio-spread">
                                <label htmlFor="existing-phonenumber" className="form__label">Bestaand nummer behouden</label>
                                <div className="form__radio-wrapper">
                                    <Field name="telephoneType" type="radio" component="input" className="form-control" value="existing" />
                                    <div className="form__radio" />
                                </div>
                            </div>

                            {telephoneType === 'existing' && <div className="">
                                <FieldArray name="phoneNumbers" component={renderPhoneNumbers}/>

                                <div className="form__group form__group--half">
                                    <Field name="providerName" type="text" component={renderField} label="Huidige Provider"/>
                                    <Field name="providerNumber" type="text" component={renderField} label="Provider klantnummer"/>
                                </div>
                            </div>}

                            <div className="specifications__radio-spread">
                                <label htmlFor="existing-phonenumber" className="form__label">Nieuw nummer</label>
                                <div className="form__radio-wrapper">
                                    <Field name="telephoneType" type="radio" component="input" className="form-control" value="new" />
                                    <div className="form__radio" />
                                </div>
                            </div>

                            {!withoutNoneOption && <div className="specifications__radio-spread">
                                <label htmlFor="existing-phonenumber" className="form__label">Geen telefoon</label>
                                <div className="form__radio-wrapper">
                                    <Field name="telephoneType" type="radio" component="input" className="form-control" value="none" />
                                    <div className="form__radio" />
                                </div>
                            </div>}

                            <Field name="telephoneType" component={renderError}/>
                        </div>
                    </div>

                </div>

            </div>
        </>
    }
}

export default TelephoneNumbers;
