import React from 'react';
import PropTypes from 'prop-types';

class CurrencyInput extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        meta: PropTypes.object.isRequired,
        input: PropTypes.object.isRequired,
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            focus: false,
        };
    }

    centsToEuro = (cents = 0) => (cents / 100).toLocaleString('nl-NL', {minimumFractionDigits: 2});

    changeValue = event => {
        const {target: {value}} = event;
        const intValue = parseInt(value.replace(/\D/g,'') || '0');

        this.setState({value: intValue});
    };

    onBlur = event => {
        event.persist();

        const {value} = this.state;
        const {input: {onBlur}} = this.props;

        this.setState({focus: false, value}, () => {
            event.target.value = value;
            onBlur(event)
        });
    };

    onFocus = event => {
        event.persist();

        const {input: {onFocus}} = this.props;

        this.setState({focus: true}, () => onFocus(event));
    }

    render() {
        const {id, input, meta: {touched, error}, label} = this.props;
        const {name} = input;
        const {value} = this.state;

        return <input
            id={id}
            type="text"
            className={'form__search-input' + (touched && error ? ' invalid' : '')}
            value={this.centsToEuro(value)}
            onChange={this.changeValue}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            placeholder={label}
            name={name}
        />;
    }
}

export default CurrencyInput;
