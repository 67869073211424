import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {change, getFormValues} from 'redux-form';
import {throttleTimeout} from '../helper/timeout';

const MIN_CHARACTER_COUNT = 3;

class KvkSelector extends React.Component {
    static propTypes = {
        formData: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            kvkCompanies: [],
            selectedCompany: {},
            apiResponse: {},
            companyDetails: {},
            totalResults: 0,
            hasFocus: false,
        };

        this.blurTimeout = null;
        this.inputRef = React.createRef();
        this.searchTimeout = null;
    }

    search = () => this.searchTimeout = throttleTimeout(this.searchTimeout, this.searchForCompanies, 900);

    updateSearchTerm = event => {
        const {target: {value}} = event;

        this.setState({searchTerm: value}, this.search);
    };

    searchForCompanies = () => {
        const {searchTerm} = this.state;

        if(searchTerm.length >= MIN_CHARACTER_COUNT) {
            fetch(`/api/coc/search?query=${searchTerm}`)
                .then(response => response.json())
                .then(data => {
                    const {results, total} = data;
                    this.setState({kvkCompanies: results, totalResults: total})
                });
        }
    };

    updateFields = () => {
        const {companyDetails: {name, city, zipcode, housenumber, street, coc, vat}} = this.state;
        const {formData} = this.props;

        if (zipcode === formData.zipcode || formData.zipcode === '') {
            this.props.change('zipcode', zipcode);
            this.props.change('housenr', housenumber);
            this.props.change('street', street);
            this.props.change('city', city);
        } else {
            this.props.change('differentInvoiceAddress', true);

            this.props.change('invoiceCompany', name);
            this.props.change('invoiceZipcode', zipcode);
            this.props.change('invoiceHousenr', housenumber);
            this.props.change('invoiceStreet', street);
            this.props.change('invoiceCity', city);
        }

        this.props.change('company', name);
        this.props.change('coc', coc);
        this.props.change('vat', vat);
    };

    selectCompany = company => {
        this.setState({selectedCompany: company, kvkCompanies: [], searchTerm: ''}, () => this.updateCompanyData(company));
    };

    updateCompanyData = company => {
        const {id} = company;
        fetch(`/api/coc/details?id=${id}`)
            .then(response => response.json())
            .then(data => this.setState({companyDetails: data}, this.updateFields));
    };

    focus = () => console.log('focus up') || this.setState({hasFocus: true});
    blur = () => this.blurTimeout = setTimeout(() => this.setState({hasFocus: false}), 200);

    render() {
        const {kvkCompanies, searchTerm, totalResults, hasFocus} = this.state;

        return <div className={`form__search ${hasFocus ? 'focus' : ''}`}>
            <input type="text" autoComplete="off" className="form__search-input" placeholder="Zoeken op bestaande gegevens" value={searchTerm} onChange={this.updateSearchTerm} onBlur={this.blur} onFocus={this.focus} />
            <svg width="17.106" height="17.105" viewBox="0 0 17.106 17.105">
                <g transform="translate(0.135)">
                    <g transform="translate(8.485 0) rotate(45)" fill="#fff" stroke="#db0032" strokeWidth="2">
                        <circle cx="6" cy="6" r="6" stroke="none" />
                        <circle cx="6" cy="6" r="5" fill="none" />
                    </g>
                    <path d="M0,0V4.99" transform="translate(4.808 12.163) rotate(45)" fill="none" stroke="#db0032" strokeLinecap="round" strokeWidth="2" />
                </g>
            </svg>


            {searchTerm.length >= MIN_CHARACTER_COUNT && kvkCompanies.length === 0 && <div className="form__search-box"><span className="form__search-item">Geen Resultaten</span></div>}

            {kvkCompanies.length > 0 && <div className="form__search-box">
                {kvkCompanies.map((company, index) => <span key={`company-${index}`} className='form__search-item' onClick={() => this.selectCompany(company)}>
                    {company.name} | {company.zipcode} {company.city}
                </span>)}
            </div>}

        </div>;
    }
}

const mapStateToProps = state => {
    return {formData: getFormValues('wizard')(state)}
};

const mapDispatchToProps = dispatch => ({
    change: (name, value) => dispatch(change('wizard', name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KvkSelector);
