import React from 'react';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';
import validate from '../validate';
import renderField from '../../components/blocks/renderField';
import renderError from "../../components/blocks/renderError";
import connect from "react-redux/es/connect/connect";
import PropTypes from 'prop-types';
import KvkSelector from '../../components/KvkSelector';

class ContactInformation extends React.Component {
    static propTypes = {
        change: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.fillNormalAddress();
    }

    componentDidUpdate(prevProps) {
        const {zipcode, houseNumber, invoiceZipcode, invoiceHousenr, sameAsContact} = this.props;

        if (zipcode !== prevProps.zipcode || houseNumber !== prevProps.houseNumber) {
            this.fillNormalAddress()
        }

        if (invoiceZipcode !== prevProps.invoiceZipcode || invoiceHousenr !== prevProps.invoiceHousenr) {
            this.fillInvoiceAddress();
        }

        if (sameAsContact !== prevProps.sameAsContact && sameAsContact) {
            const {contact, email, phone} = this.props;

            this.props.change('projectContact', contact);
            this.props.change('projectEmail', email);
            this.props.change('projectPhone', phone);
        }
    }

    fillNormalAddress = () => {
        const {zipcode, houseNumber} = this.props;

        this.findAddressData(zipcode, houseNumber).then(data => {
            if (data.street) {
                this.props.change('street', data.street);
                this.props.change('city', data.city);
            }
        });
    };

    fillInvoiceAddress = () => {
        const {invoiceZipcode, invoiceHousenr} = this.props;

        this.findAddressData(invoiceZipcode, invoiceHousenr).then(data => {
            if (data.street) {
                this.props.change('invoiceStreet', data.street);
                this.props.change('invoiceCity', data.city);
            }
        });
    };

    findAddressData = async (zipcode, houseNumber) => {
        if (/[0-9]{4}[a-zA-Z]{2}/.test(zipcode) && /\d+/.test(houseNumber))
        {
            const json = await fetch(`/api/address?zipcode=${zipcode}&number=${houseNumber}`);
            return await json.json();
        }

        return {};
    };

    render() {
        const {handleSubmit, previousPage, hasInvoiceAddress, discount, formData, contractDuration} = this.props;

        return (
            <>
                <div className="form__category">
                    <h2 className="h2 card__title">Uw gegevens</h2>

                    <KvkSelector />

                    <h3 className="h3">Persoonlijke informatie</h3>


                    <div className="form__group">
                        <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-gender-men" name="gender" value="m" />
                                <div className="form__radio" />
                                <label htmlFor="radio-gender-men" className="form__label">Dhr.</label>
                            </div>

                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-gender-women" name="gender" value="f" />
                                <div className="form__radio" />
                                <label htmlFor="radio-gender-women" className="form__label">Mw.</label>
                            </div>

                            <div className="form__radio-wrapper">
                                <Field component="input" type="radio" id="radio-gender-none" name="gender" value="o" />
                                <div className="form__radio" />
                                <label htmlFor="radio-gender-none" className="form__label">Geen van beide</label>
                            </div>
                        </div>
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="initials" type="text" component={renderField} label="Voornaam"/>
                        <Field name="lastname" type="text" component={renderField} label="Achternaam"/>
                    </div>

                    <div className="form__group">
                        <Field name="company" type="text" component={renderField} label="Bedrijfsnaam"/>
                    </div>
                    <div className="form__group">
                        <Field name="contact" type="text" component={renderField} label="Contactpersoon"/>
                    </div>

                    <div className="form__group">
                        <Field name="email" type="text" component={renderField} label="E-mail"/>
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="phone" type="text" component={renderField} label="Telefoonnummer"/>
                        <Field name="mobilePhone" type="text" component={renderField} label="Mobiel"/>
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="coc" type="text" component={renderField} label="KvK Nummer"/>
                        <Field name="vat" type="text" component={renderField} label="BTW Nummer"/>
                    </div>
                </div>

                <div className="form__category">
                    <h3 className="h3">Betaalgegevens</h3>

                    <div className="form__group">
                        <div className="form__input-wrapper form__input-wrapper--radio">
                            <div className="form__radio-wrapper">
                                <Field type="radio" component="input" id="paymentFrequency" name="paymentFrequency" value="monthly" />
                                <div className="form__radio" />
                                <label htmlFor="paymentFrequency" className="form__label">Maandelijkse Automatische Incasso</label>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="form__category">
                    <h3 className="h3">Installatieadres</h3>

                    <div className="form__group form__group--third">
                        <Field name="street" type="text" component={renderField} label="Straatnaam" />
                        <Field name="housenr" type="text" component={renderField} label="Huisnummer" />
                        <Field name="appendix" type="text" component={renderField} label="Toevoeging" />
                    </div>


                    <div className="form__group form__group--half">
                        <Field name="zipcode" type="text" component={renderField} label="Postcode"/>
                        <Field name="city" type="text" component={renderField} label="Plaats"/>
                    </div>

                    <div className="form__group">
                        <div className="form__input-wrapper">
                            <div className="form__checkbox-wrapper">
                                <Field name="differentInvoiceAddress" id="odd-adress" component="input" type="checkbox" value="yes"/>
                                <div className="form__checkbox" />
                                <label htmlFor="odd-adress" className="form__label">Afwijkend factuuradres</label>
                            </div>
                        </div>
                    </div>

                </div>

                {hasInvoiceAddress && <div className="form__category">
                    <h3 className="h3">Factuuradres</h3>
                    <div className="form__group">
                        <Field name="invoiceCompany" type="text" component={renderField} label="Bedrijfsnaam"/>
                    </div>
                    <div className="form__group">
                        <Field name="invoiceContact" type="text" component={renderField} label="Contactpersoon"/>
                    </div>
                    <div className="form__group">
                        <Field name="invoiceEmail" type="email" component={renderField} label="E-mail"/>
                    </div>
                    <div className="form__group form__group--third">
                        <Field name="invoiceStreet" type="text" component={renderField} label="Straatnaam"/>
                        <Field name="invoiceHousenr" type="text" component={renderField} label="Huisnummer"/>
                        <Field name="invoiceAppendix" type="text" component={renderField} label="Toevoeging"/>
                    </div>
                    <div className="form__group form__group--half">
                        <Field name="invoiceZipcode" type="text" component={renderField} label="Postcode"/>
                        <Field name="invoiceCity" type="text" component={renderField} label="Plaats"/>
                    </div>
                </div>}

                <div className="form__category">
                    <h3 className="h3">Project verantwoordelijke</h3>
                    <div className="form__checkbox-wrapper w-100">
                        <Field name="sameAsContact" id="sameAsContact" component="input" type="checkbox" value="yes"/>
                        <div className="form__checkbox" />
                        <label htmlFor="sameAsContact" className="form__label">Zelfde als contactpersoon</label>
                    </div>
                    <div className="form__group">
                        <Field name="projectContact" type="text" component={renderField} label="Contactpersoon" />
                    </div>

                    <div className="form__group form__group--half">
                        <Field name="projectEmail" type="email" component={renderField} label="E-mail"/>
                        <Field name="projectPhone" type="text" component={renderField} label="Telefoonnummer"/>
                    </div>
                </div>

                <div className="form__category">
                    <h3 className="h3">Contract details</h3>

                    <div className="form__group">
                        <div className="form__input-wrapper form__input-wrapper--radio">
                            <div className="form__radio-wrapper">
                                <Field type="radio" component="input" id="payment-type-month" name="contractDetails" value="asap" />
                                <div className="form__radio" />
                                <label htmlFor="payment-type-month" className="form__label">Zo snel mogelijk</label>
                            </div>

                            <div className="form__radio-wrapper">
                                <Field type="radio" component="input" id="payment-type-yearly" name="contractDetails" value="month" />
                                <div className="form__radio" />
                                <label htmlFor="payment-type-yearly" className="form__label">Binnen een maand</label>
                            </div>
                        </div>
                    </div>

                    <div className="form__group">
                        <div className="form__input-wrapper">
                            <Field name="notes" component="textarea" className="form__input" placeholder="Opmerkingen"/>
                        </div>
                    </div>

                    {!discount && <div className="form__group">
                        <Field name="partner" type="text" component={renderField} label="Partnernaam"/>
                    </div>}

                    {discount && <div className="form__group">
                        <Field name="membershipNumber" type="text" component={renderField} label="Lidnummer"/>
                    </div>}


                    <div className="form__group">
                        <div className="form__input-wrapper">
                            <div className="form__checkbox-wrapper w-100">
                                <Field name="agreedPrivacy" id="agreedPrivacy" component="input" type="checkbox" value="yes"/>
                                <div className="form__checkbox" />
                                <label htmlFor="agreedPrivacy" className="form__label">Ik ga akkoord met de <a
                                    href="https://omniaconnect.nl/wp-content/uploads/2023/02/Omnia-Connect-Algemene-voorwaarden.pdf"
                                    target="_blank" className="form__label--colored">algemene leveringsvoorwaarden</a>.</label>
                            </div>
                            <Field name="agreedPrivacy" component={renderError}/>

                            <div className="form__checkbox-wrapper w-100">
                                <Field name="agreedInstallation" id="agreedInstallation" component="input" type="checkbox" value="yes"/>
                                <div className="form__checkbox" />
                                <label htmlFor="agreedInstallation" className="form__label">Ik ga akkoord met de <a
                                    href="https://omniaconnect.nl/wp-content/uploads/2023/02/OmniaConnect_Installatievoorwaarden_DEF.pdf"
                                    target="_blank" className="form__label--colored">installatie voorwaarden</a></label>
                            </div>
                            <Field name="agreedInstallation" component={renderError}/>

                            <div className="form__checkbox-wrapper w-100">
                                <Field name="agreedContractTerm" id="agreedContractTerm" component="input" type="checkbox" value="yes"/>
                                <div className="form__checkbox" />
                                <label htmlFor="agreedContractTerm" className="form__label">Ik ga akkoord met een contracttermijn van {contractDuration} maanden</label>
                            </div>
                            <Field name="agreedContractTerm" component={renderError}/>
                        </div>
                    </div>
                </div>

            </>
        );
    }

}

ContactInformation = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(ContactInformation);

const selector = formValueSelector('wizard');

const mapStateToProps = state => {
    const {contractDuration} = state.orderProcess;
    const sameAsContact = selector(state, 'sameAsContact')

    return {
        projectContact: selector(state, 'projectContact'),
        projectEmail: selector(state, 'projectEmail'),
        projectPhone: selector(state, 'projectPhone'),
        contact: selector(state, 'contact'),
        email: selector(state, 'email'),
        phone: selector(state, 'phone'),
        hasInvoiceAddress: selector(state, 'differentInvoiceAddress'),
        discount: selector(state, 'discount'),
        formData: getFormValues('wizard')(state),
        street: selector(state, 'street'),
        zipcode: selector(state, 'zipcode'),
        houseNumber: selector(state, 'housenr'),
        invoiceHousenr: selector(state, 'invoiceHousenr'),
        invoiceZipcode: selector(state, 'invoiceZipcode'),
        sameAsContact: sameAsContact,
        contractDuration,
    };
};

ContactInformation = connect(
    mapStateToProps
)(ContactInformation);

export default ContactInformation;
