import React from 'react';
import {Field, getFormValues, reduxForm} from 'redux-form';
import validate from '../validate';
import connect from "react-redux/es/connect/connect";
import {renderGender} from '../../helper/text';
import renderField from '../../components/blocks/renderField';

class Confirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orderRequestInProgress: false,
        };
    }

    render() {
        const {formData} = this.props;

        return (
            <>
                <h2 className="h2 card__title">Bevestiging</h2>

                <div className="confirmation__blocks">
                    <div className="confirmation__block">
                        <h3 className="h3">Persoonlijke informatie</h3>

                        <p className="confirmation__description">{renderGender(formData.gender)} {formData.initials} {formData.lastName}</p>
                        <p className="confirmation__description">{formData.company}</p>
                        <p className="confirmation__description">{formData.contact}</p>
                        <p className="confirmation__description"><b>T</b> {formData.phone}</p>
                        <p className="confirmation__description"><b>M</b> {formData.email}</p>
                        <p className="confirmation__description">{formData.coc}</p>
                        <p className="confirmation__description">{formData.vat}</p>
                    </div>
                    <div className="confirmation__block">
                        <h3 className="h3">Betaalgegevens</h3>

                        <p className="confirmation__description">{formData.paymentFrequency === 'monthly' ? 'Incasso per maand' : 'Op rekening per jaar'}</p>
                        <p className="confirmation__description">{formData.iban}</p>
                    </div>
                    <div className="confirmation__block">
                        <h3 className="h3">Installatieadres</h3>

                        <p className="confirmation__description">{formData.street} {formData.housenr} {formData.appendix}</p>
                        <p className="confirmation__description">{formData.zipcode}, {formData.city}</p>

                        <h3 className="h3" style={{marginTop: '4rem'}}>Factuuradres</h3>

                        {!formData.differentInvoiceAddress && <>
                            <p className="confirmation__description">{formData.company}</p>
                            <p className="confirmation__description">{formData.contact}</p>
                            <p className="confirmation__description"><b>M</b> {formData.email}</p>
                            <p className="confirmation__description">{formData.street} {formData.housenr} {formData.appendix}</p>
                            <p className="confirmation__description">{formData.zipcode}, {formData.city}</p>
                        </>}

                        {formData.differentInvoiceAddress && <>
                            <p className="confirmation__description">{formData.invoiceCompany}</p>
                            <p className="confirmation__description">{formData.invoiceContact}</p>
                            <p className="confirmation__description"><b>M</b> {formData.invoiceEmail}</p>
                            <p className="confirmation__description">{formData.invoiceStreet} {formData.invoiceHousenr} {formData.invoiceAppendix}</p>
                            <p className="confirmation__description">{formData.invoiceZipcode}, {formData.invoiceCity}</p>
                        </>}

                    </div>
                    <div className="confirmation__block">
                        <h3 className="h3">Contractdetails</h3>

                        <p className="confirmation__description">{formData.telephoneType === 'existing' ? 'Bestaand nummer' : 'Nieuw nummer'}</p>
                        <p className="confirmation__description">{formData.contractDetails === 'asap' ? 'Zo snel mogelijk' : 'Binnen een maand'}</p>
                        <p className="confirmation__description"><b>Opmerkingen</b> {formData.notes ? formData.notes : 'Geen'}</p>
                    </div>
                </div>
                <div className="confirmation__blocks">
                    <p style={{width: '100%', padding: '10px 0 30px'}}>
                        <label className="confirmation__description" htmlFor="sendCopyTo">Kopie offerte sturen naar:</label>
                        <Field id="sendCopyTo" name="sendCopyTo" label="E-mail" className="form-control" placeholder="E-mail" component={renderField}/>
                    </p>
                </div>

            </>
        );
    }
}

Confirmation = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(Confirmation);

Confirmation = connect(
    state => ({
        formData: getFormValues('wizard')(state)
    }),
)(Confirmation);

export default Confirmation;
