import {
    TOOL_UPDATE_FACILITY_XL_CUSTOMER,
    UPDATE_ORDER_PROCESS_CONTRACT_DURATION,
    UPDATE_ORDER_PROCESS_RELATED_PRODUCT_SETTINGS
} from './actionTypes';


export const updateContractDuration = contractDuration => ({
    type: UPDATE_ORDER_PROCESS_CONTRACT_DURATION,
    payload: {contractDuration},
});

export const updateRelatedProductSettings = settings => ({
    type: UPDATE_ORDER_PROCESS_RELATED_PRODUCT_SETTINGS,
    payload: {settings},
});

export const updateFacilityXlCustomer = facilityXLCustomer => ({
    type: TOOL_UPDATE_FACILITY_XL_CUSTOMER,
    payload: {facilityXLCustomer},
});
