import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Field, getFormValues, reduxForm, submit} from 'redux-form';
import renderField from '../../components/blocks/renderField';
import IntakeForm from '../../intake/components/alarm/intakeForm';
import Schematics from '../../intake/components/schematics';
import {submitIntakeExtraInfoForm, submitIntakeForm} from '../../intake/helpers/submit';
import intakeInitialValues from '../../intake/helpers/intakeInitialValues';

import {connect} from 'react-redux';
import InstallationDatePicker from '../../order/installation/InstallationDatePicker';
import IntakeImages from '../../intake/components/intakeImages';
import IntakeExtraInfoImages from './intakeExtraInfoImages';
import {updateIntakeId} from '../../intake/store/actions';
import InstallationConfirmation from '../../order/installation/InstallationConfirmation';
import intakeAdditionalInitialValues from '../helpers/intakeAdditionalInfoInitialValues';
import {validate} from '../helpers/validate';

function ExtraInfoForm({intakeId, submitForm, updateIdentifier}) {

    useEffect(() => {
        updateIdentifier(intakeId);
    }, []);

    const triggerSubmit = event => {
        event.preventDefault();

        if (confirm("Wilt u het intake formulier opslaan?")) {
            submitForm('intake-extra');
        } else {
            return false;
        }
    }

    return <section className="specifications intake">
        <div className="container container--large small-column-first">
            <div className="card">
                <h3 className="h3">Aanvullende Gegevens</h3>
                <form>
                    <div className="intake mb-3">
                        <div className="form__group">
                            <Field
                                name="manager"
                                type="text"
                                component={renderField}
                                label="Manager"
                            />
                        </div>
                        <div className="form__group">
                            <Field
                                name="installationCode"
                                type="text"
                                component={renderField}
                                label="Monteurs Code"
                            />
                        </div>
                        <div className="form__group">
                            <div className="input-group react-datepicker-wrapper">
                                <Field name="endDate" type="date" component={renderField} label="Einddatum" />
                            </div>
                            <small style={{color: '#aaa', marginTop: '10px'}}>Datum einddatum contract huidige meldkamer</small>
                        </div>

                        <div className="form__group">
                            <Field name="comments" component="textarea" className="form__input" placeholder="Opmerkingen" rows={5} />
                        </div>

                        <Field name="intakeImages" component={props => {
                            return <IntakeExtraInfoImages intakeId={intakeId} value={props.input.value} onChange={value => props.input.onChange(value)} />
                        }} label="Foto's" />


                    </div>

                    <button className="btn btn-success mt-3 mb-3" onClick={triggerSubmit}>Opslaan</button>
                </form>

            </div>

        </div>

    </section>;
}

ExtraInfoForm.propTypes = {

};

const mapDispatchToProps = dispatch => ({
    updateIdentifier: identifier => dispatch(updateIntakeId(identifier)),
    submitForm: form => dispatch(submit(form)),
});

ExtraInfoForm = reduxForm({
    form: 'intake-extra',
    onSubmit: submitIntakeExtraInfoForm,
    initialValues: intakeAdditionalInitialValues(),
    validate
})(ExtraInfoForm);

export default ExtraInfoForm = connect(
    state => ({
        formData: getFormValues('intake-extra')(state)
    }), mapDispatchToProps
)(ExtraInfoForm);
