import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getFormValues} from 'redux-form';
import {sendOrderConfirmation} from '../helpers';

class SendOrderConfirmationButton extends React.Component {
    static propTypes = {
        formData: PropTypes.object.isRequired,
    };

    sendOrderConfirmation = () => {
        const {formData} = this.props;

        sendOrderConfirmation(formData);
    };

    render() {
        return <button type="button" className="btn" onClick={() => this.sendOrderConfirmation()}>
            Email Offerte
        </button>;
    }
}

const mapStateToProps = state => {
    return {formData: getFormValues('wizard')(state)};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SendOrderConfirmationButton);
