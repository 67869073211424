import {exportToBlob} from '@excalidraw/excalidraw';
import store from '../store/store';

export async function uploadFile(uri, file, fieldName = 'file') {
    if (file instanceof File) {
        const body = new FormData();
        body.append(fieldName, file);

        const request = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: body,
        };

        return await fetch(uri, request);
    }
}

export function uploadSchematics(intakeId, blob) {
    const file = new File([blob], 'schematics.png');

    return uploadFile(`/api/security-intake/${intakeId}/image`, file, 'file');
}

export async function exportSchematics() {
    const {intake: {identifier, canvas}} = store.getState();

    const blob = await exportToBlob({
        elements: canvas.current.getSceneElements(),
        appState: canvas.current.getAppState(),
        getDimensions: (width, height) => ({width, height}),
        files: canvas.current.getFiles(),
        exportPadding: 10,
    });

    return await uploadSchematics(identifier, blob);
}
