import {Field} from 'redux-form';
import renderField from '../../../components/blocks/renderField';
import React from 'react';

const renderPositionFields = ({fields, meta: {error}, label = ''}) => (
    <div className="w-100">
        <label>{label}</label>
        {fields.map((camera, index) => (
            <div className="flex between relative phone-field-container" key={index}>
                <Field
                    name={camera}
                    type="text"
                    component={renderField}
                    label=""
                />

                <div className="number-btn-container">
                    {index === 0 && <button type="button"  className="accent pointer" onClick={() => fields.push()}>
                        <i className="fas fa-plus" />
                    </button>}
                    {index > 0 && <button type="button" className="warning pointer" onClick={() => fields.remove(index)}>
                        <i className="fas fa-times" />
                    </button>}
                </div>
            </div>

        ))}
        {error && <li className="error">{error}</li>}
    </div>
);

export default renderPositionFields;
