import React from 'react';
import PropTypes from 'prop-types';
import {uploadIntakeImage} from '../../order/helpers';

class InstallationImages extends React.Component {
    static propTypes = {
        value: PropTypes.array.isRequired,
        onChange: PropTypes.func.isRequired,
        intakeId: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            images: props.value,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const {images} = this.state;
        const {onChange} = this.props;

        if (prevState.images !== images) {
            onChange(images);
        }
    }

    fileAdded = event => {
        const {intakeId} = this.props;
        const {files} = event.target;
        const newImage = files[0];

        uploadIntakeImage(intakeId, newImage).then(result => {
            if (result) {
                const {images} = this.state;
                this.setState({images: [...images, result]});
            }
        });
    };

    removeImage = id => {
        const {images} = this.state;

        const filtered = images.filter(image => image.id !== id);
        this.setState({images: [...filtered]});
    };

    render() {
        const {images} = this.state;

        return <>
            <div className="row">
                <div className="col-12">
                    <div className="flex border-dotted rounded p-3 center files-dropzone invisible-file-input">
                        <input className="order-image-input" accept=".jpg,.jpeg,.png" type="file" onChange={this.fileAdded} title="Klik of sleep een foto om te uploaden" />
                        <i className="fas fa-plus" />
                    </div>
                </div>
            </div>
            <div className="intake-images flex items-start">
                {images.map(image => <div key={`order-image-${image.id}`} className="col-4 order-image-container mt-2">
                    <i className="fas fa-times-circle" onClick={() => this.removeImage(image.id)} />
                    <img src={`/images/${image.filename}`} />
                </div>)}
            </div>
        </>;
    }
}

export default InstallationImages;
