import React from 'react';
import Product from "./Product";
import {change} from "redux-form";
import {getQueryParameter} from '../helper/queryString';

class ProductList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            exclusiveProducts: [],
            open: props.index === 0,
        }
    }

    componentDidMount() {
        const {products} = this.props;

        this.setState({products});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {formData, products, dispatch} = this.props;

        if (formData !== prevProps.formData) {
            this.makeSureMandatoryProductsAreSelectedForProductGroup(products, formData, dispatch);
        }

        if (products !== prevProps.products) {
            if (this.isRadioProductGroup()) {
                this.setState({products: products, exclusiveProducts: products});
                // enable at least one option for radio product group
                const hasSelectedOptionInGroup = products.filter(product => formData['product_' + product.id]).length > 0;
                if (false === hasSelectedOptionInGroup) {
                    dispatch(change('wizard', `product_${products[0].id}`, true));
                }
            } else if (this.hasMaximumInternetSpeed()) {
                const filtered = this.filterInternetProducts(products);
                this.setState({products: filtered, exclusiveProducts: filtered.filter(product => product.max_speed)});
            } else {
                this.setState({products});
            }
        }
    }

    makeSureMandatoryProductsAreSelectedForProductGroup(products, formData, dispatch) {
        let amount = 0;

        if (products.length === 0) {
            return;
        }

        products.forEach((product, index) => {
            if (index !== products.length - 1 && formData['product_' + product.id]) {
                // get highest base amount
                amount = Math.max(amount, formData['product_' + product.id] === true ? 1 : parseInt(formData['product_' + product.id]));
            }
        });

        const mandatoryProducts = products.filter(product => product.is_mandatory);
        if (amount > 0 && mandatoryProducts.length > 0) {
            // set each mandatory to either true, or the max amount for a product in this group
            mandatoryProducts.forEach((product, index) => {
                if (product.is_multiple) {
                    dispatch(change('wizard', `product_${product.id}`, amount));
                } else {
                    dispatch(change('wizard', `product_${product.id}`, true));
                }
            });
        }
    }

    filterInternetProducts = products => {
        const dslMaxSpeed = Math.max(parseInt(getQueryParameter('dsl')), 0);
        const fiberMaxSpeed = Math.max(parseInt(getQueryParameter('fiber')), 0);
        let filtered = products.filter(product => !(product.type === 'dsl' && product.max_speed && product.max_speed > dslMaxSpeed));

        return filtered.filter(product => !(product.type === 'fiber' && product.max_speed && product.max_speed > fiberMaxSpeed));
    }

    hasMaximumInternetSpeed = () => parseInt(getQueryParameter('dsl')) > 0 || parseInt(getQueryParameter('fiber'));
    isRadioProductGroup = () => this.props.products.filter(product => product.is_radio_product_group).length > 0;

    getChangeHandler() {
        // exclusiveProducts contains products that should at most have a single product enabled
        const {exclusiveProducts} = this.state;

        if (exclusiveProducts.length > 0) {
            const inputNames = exclusiveProducts.map(product => `product_${product.id}`);

            return event => {
                const {target: {name}} = event;

                this.props.dispatch(change('wizard', name, true));

                // Find other exclusive products and disable the rest
                inputNames.filter(fieldName => fieldName !== name).forEach(fieldName => {
                    this.props.dispatch(change('wizard', fieldName, false));
                });
            }
        }

        return null;
    }

    toggleOpened = event => {
        event.preventDefault();
        const {open} = this.state;

        this.setState({open: !open});
    };

    render() {
        const {title, description, formData} = this.props;
        const {products, open} = this.state;

        if (products.length === 0) {
            return <></>;
        }

        return <div className="accordion__wrapper">
            <div className={`accordion ${open ? 'accordion--active' : ''}`}>
                <div className="accordion__head">
                    <div className="accordion__title">{title}</div>
                    <a href="#" className="accordion__button" onClick={this.toggleOpened}>Tonen</a>
                </div>

                <div className="accordion__body">
                    {/*<div className="specifications__item-wrapper">*/}
                    {/*    {description && <p>*/}
                    {/*        {description}*/}
                    {/*    </p>}*/}
                    {/*</div>*/}

                    {products.map(product => <Product
                        key={`product${product.product_id || product.id}`}
                        product={product}
                        name={`product_${product.product_id || product.id}`}
                        hasMobileSubscriptionProducts={product.is_mobile && parseInt(formData[`product_${product.product_id || product.id}`]) > 0}
                        changeHandler={this.getChangeHandler()}
                    />)}
                </div>
            </div>
        </div>;
    }
}

export default ProductList;
