import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getFormValues} from 'redux-form';
import {generatePdfRequest} from '../helpers';

class DownloadQuoteButton extends React.Component {
    static propTypes = {
        formData: PropTypes.object.isRequired,
    };

    generatePdf = () => {
        const {formData} = this.props;

        generatePdfRequest(formData);
    };

    render() {
        return <button type="button" className="btn" onClick={() => this.generatePdf()}>
            Download
        </button>;
    }
}

const mapStateToProps = state => {
    return {formData: getFormValues('wizard')(state)};
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadQuoteButton);
