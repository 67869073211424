import React from 'react';
import {getFormValues, reduxForm} from "redux-form";
import validate from "./validate";
import connect from "react-redux/lib/connect/connect";
import {calculate} from "./helpers";
import {renderPrice} from "../components/blocks/renderPrice";

export const AFFILIATE_DISCOUNT = -1000;

class PriceList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            monthlyCosts: [],
            oneOffCosts: [],
            totalMonthlyCosts: 0,
            totalOneOffCosts: 0,
            startingDiscount: 0,
            startingDiscountMonths: 0,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formData !== this.props.formData) {
            this.update();
        }
    }

    update() {
        calculate(this.props.formData)
            .then((response) => this.setState({
                monthlyCosts: response.monthlyCosts,
                oneOffCosts: response.oneOffCosts,
                totalMonthlyCosts: response.totalMonthlyCosts,
                totalOneOffCosts: response.totalOneOffCosts,
                startingDiscount: response.startingDiscount,
                startingDiscountMonths: response.startingDiscountMonths,
            }));
    }

    renderTotal(totalMonthlyCosts, totalOneOffCosts, startingDiscount, startingDiscountMonths) {
        return <div className="specifications__price-items-total">
            <div className="specifications__price-item">
                <div className="specifications__price-info">
                    <span className="specifications__price-heading">Totaal<span className="m-hide">prijs</span> eenmalig</span>
                </div>

                <span className="specifications__price">&euro; {renderPrice(totalOneOffCosts, ',00')}</span>
            </div>

            <div className="specifications__price-item">
                <div className="specifications__price-info">
                    <span className="specifications__price-heading">Totaal<span className="m-hide">prijs</span> maandelijks</span>
                </div>

                <span className="specifications__price">
                    {startingDiscount  > 0 && startingDiscountMonths > 0 && <>
                        &euro; {renderPrice(totalMonthlyCosts - startingDiscount, ',00')} p/m
                    </>}
                    {!(startingDiscount  > 0 && startingDiscountMonths > 0) && <>
                        &euro; {renderPrice(totalMonthlyCosts, ',00')} p/m
                    </>}
                </span>
            </div>

            {startingDiscount  > 0 && startingDiscountMonths > 0 && <div className="specifications__price-item">
                <div className="specifications__price-info">
                    <span className="specifications__price-heading accent">Na {startingDiscountMonths} maanden</span>
                </div>

                <span className="specifications__price">
                    &euro; {renderPrice(totalMonthlyCosts, ',00')} p/m
                </span>
            </div>}
        </div>;
    }

    renderTotalDiscount = totalDiscount => {
        if (totalDiscount > 0) {
            return <div className="text-success">
                <p className="text-center mt-1 text-success">
                    <span className="h6 text-center inline-block mr-2">Uw voordeel: &euro; {renderPrice(totalDiscount, ',00')} per maand</span>
                </p>
            </div>;
        }

    };

    render() {
        const {totalMonthlyCosts, totalOneOffCosts, monthlyCosts, oneOffCosts, startingDiscount, startingDiscountMonths} = this.state;
        const {formData} = this.props;
        const phoneNumbers = formData.phoneNumbers ? formData.phoneNumbers.filter(phoneNumber => phoneNumber !== '') : [];
        let totalDiscount = 0;

        return <>
            <div className="specifications__price-item">
                <div className="specifications__price-info w-100">
                    <span className="specifications__price-heading">
                        Maandelijkste kosten
                    </span>

                    {monthlyCosts.map((option, index) => {
                        if (option.price < 0) {
                            totalDiscount += Math.abs(option.price);
                        }

                        if (!option.show || option.included) {
                            return null;
                        }

                        return <div key={index} className="specifications__price-info">
                            <div className="flex between items-end">
                                <p className="specifications__price-description">{option.description}</p>
                                <span className="specifications__price">
                                    {option.included ? <del>&euro; {renderPrice(option.price, ',00')}</del> : <span>&euro; {renderPrice(option.price, ',00')}</span>}
                                </span>
                            </div>
                        </div>;
                    })}

                </div>
            </div>


            {phoneNumbers.length > 0 && <div className="specifications__price-item col flex-start">
                <div className="specifications__price-info">
                    <span className="specifications__price-heading">
                        Telefoonnummers
                    </span>
                </div>

                {phoneNumbers.map((phoneNumber, index) => <p key={index}>{phoneNumber}</p>)}

                <hr/>
            </div>}

            <div className="specifications__price-item">
                <div className="specifications__price-info w-100">
                    <span className="specifications__price-heading">
                        Eenmalige kosten
                    </span>

                    {oneOffCosts.map((option, index) => {
                        if (!option.show || option.included) {
                            return null;
                        }

                        return <div key={index} className="specifications__price-info">
                            <div className="flex between items-end">
                                <p className="specifications__price-description">{option.description}</p>
                                <span className="specifications__price">
                                    {option.included ? <del>&euro; {renderPrice(option.price, ',00')}</del> : <span>&euro; {renderPrice(option.price, ',00')}</span>}
                                </span>
                            </div>
                        </div>
                    })}

                </div>
            </div>

            <div className="specifications__divider" />

            {this.renderTotal(totalMonthlyCosts, totalOneOffCosts, startingDiscount, startingDiscountMonths)}
        </>;

        //     <ul className={"priceList"}>
        //         {monthlyCosts.map((option, index) => {
        //             if (option.price < 0) {
        //                 totalDiscount += Math.abs(option.price);
        //             }
        //
        //             if (!option.show || option.included) {
        //                 return null;
        //             }
        //
        //             return <li key={index}>
        //                 <div className="priceListDescription">{option.description}</div>
        //                 <div className={"priceListPrice " + (option.price < 0 ? 'text-success' : '')}>
        //                     {option.included ? <del>&euro; {renderPrice(option.price, ',00')}</del> : <span>&euro; {renderPrice(option.price, ',00')}</span>}
        //                 </div>
        //             </li>;
        //         })}
        //     </ul>
        //     <hr/>
        //     {phoneNumbers.length > 0 && <>
        //         <h5>Telefoonnummers</h5>
        //         <ul>
        //             {formData.phoneNumbers.map((phoneNumber, index) => <li key={index}>{phoneNumber}</li>)}
        //         </ul>
        //         <hr/>
        //     </>}
        //     <h5>Eenmalige kosten</h5>
        //     <ul className={"priceList"}>
        //         {oneOffCosts.map((option, index) => {
        //             if (!option.show || option.included) {
        //                 return null;
        //             }
        //
        //             return <li key={index}>
        //                 <div className={"priceListDescription"}>{option.description}</div>
        //                 <div className={"priceListPrice " + (option.price < 0 ? 'text-success' : '')}>&euro; {renderPrice(option.price, ',00')}</div>
        //             </li>
        //         })}
        //     </ul>
        //     <hr/>
        //     <h6 className="text-center">Totaal prijs:</h6>
        //     {this.renderTotal(totalMonthlyCosts, totalOneOffCosts)}
        // </div>
    }
}


PriceList = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(PriceList);

PriceList = connect(
    state => ({
        formData: getFormValues('wizard')(state)
    }),
)(PriceList);

export default PriceList;
