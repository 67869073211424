import {Field} from "redux-form";
import renderField from "./renderField";
import React from "react";

function sanitizeNumber(value) {
    //const {target: {value}} = event;

    return value.replace(/\D/g,'');
}

const renderPhoneNumbers = ({fields, meta: {error}}) => (
    <div>
        {fields.map((phoneNumber, index) => (
            <div className="flex between relative phone-field-container" key={index}>
                <Field
                    name={phoneNumber}
                    type="text"
                    component={renderField}
                    label="0611223344"
                    normalize={sanitizeNumber}
                />


                <div className="number-btn-container">
                    {index === 0 && <button type="button"  className="accent pointer" onClick={() => fields.push()}>
                        <i className="fas fa-plus" />
                    </button>}
                    {index > 0 && <button type="button" className="warning pointer" onClick={() => fields.remove(index)}>
                        <i className="fas fa-times" />
                    </button>}
                </div>
            </div>

        ))}
        {error && <p className="warning">{error}</p>}
    </div>
);

export default renderPhoneNumbers;
