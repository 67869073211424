import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Schematics from '../schematics';
import IntakeForm from './intakeForm';
import {updateIntakeId} from '../../store/actions';
import {getFormValues} from 'redux-form';

class Intake extends React.Component {
    static propTypes = {
        updateIdentifier: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {intakeId} = this.props;

        this.props.updateIdentifier(intakeId);
    }

    render() {

        return <section className="specifications intake">
            <div className="container container--large small-column-first">
                <div className="card intake-form-card">
                    <h3 className="h3">Intake Gegevens</h3>
                    <IntakeForm />
                </div>
                <div className="card">

                    <form>
                        <div className="row">
                            <div className="col-12">
                                <Schematics />
                            </div>
                        </div>
                    </form>

                </div>

            </div>

        </section>;
    }
}

const mapStateToProps = state => {
    const {} = state;

    return {formData: getFormValues('intake')(state)};
};

const mapDispatchToProps = dispatch => ({
    updateIdentifier: identifier => dispatch(updateIntakeId(identifier)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intake);
