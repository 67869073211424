import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, getFormValues, reduxForm, submit} from 'redux-form';
import renderField from '../../../components/blocks/renderField';
import FieldArray from 'redux-form/lib/FieldArray';
import renderPositionFields from '../fields/renderPositionFields';
import {validate} from '../../helpers/validate';
import renderError from '../../../components/blocks/renderError';
import {submitIntakeForm} from '../../helpers/submit';
import moment from 'moment';
import intakeInitialValues from '../../helpers/intakeInitialValues';

class IntakeForm extends React.Component {
    static propTypes = {
        submitForm: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            minimumAlarmProducts: [],
        };
    }


    componentDidMount() {
        this.fetchMinimumProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {formData} = this.props;
        const {insuranceRequirement} = prevProps.formData;

        if (formData !== prevProps.formData && formData.insuranceRequirement !== insuranceRequirement) {
            this.props.change('requirementClass', 0);
        }
    }

    fetchMinimumProducts = () => {
        fetch(`/api/minimum-alarm-products/list`)
            .then((response) => response.json())
            .then((responseData) => {
                this.setState({minimumAlarmProducts: responseData});
            })
            .catch(error => console.warn(error));
    }

    triggerSubmit = event => {
        event.preventDefault();

        if (confirm("Wilt u het intake formulier opslaan?")) {
            this.props.submitForm('intake');
        } else {
            return false;
        }
    }

    render() {
        const {formData: {insuranceRequirement, requirementClass}} = this.props;
        const {minimumAlarmProducts} = this.state;

        return <div>
            <div className="form__group">
                <label className="mb-2">Is er een verzekeringseis?</label>
                <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-insurance-yes" name="insuranceRequirement" value="1" />
                        <div className="form__radio" />
                        <label htmlFor="radio-insurance-yes" className="form__label">Ja</label>
                    </div>
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-insurance-no" name="insuranceRequirement" value="0" />
                        <div className="form__radio" />
                        <label htmlFor="radio-insurance-no" className="form__label">Nee</label>
                    </div>
                </div>
            </div>

            {insuranceRequirement === '1' && <div className="form__group">
                <p>
                    Bij een verzekeringseis verzoeken we de klant om alle relevante gegevens, zoals de polis, op te sturen naar <a className="accent" href="mailto:omnia@safety-tech.nl">omnia@safety-tech.nl</a>.
                </p>
            </div>}

            {insuranceRequirement === '1' && <div className="form__group">
                <label>Verzekerings Klasse</label>
                <Field name="requirementClass" component="select" className="form__input mb-4">
                    <option value={0}>-</option>
                    <option value={1}>Klasse 1</option>
                    <option value={2}>Klasse 2</option>
                    <option value={3}>Klasse 3</option>
                    <option value={4}>Klasse 4</option>
                </Field>
            </div>}

            {(insuranceRequirement === '0' || (requirementClass > 0 && requirementClass <= 2)) && <>
                {insuranceRequirement === '1' && <div className="mb-4">
                    <label className="block mb-2">Om te voldoen aan Klasse {requirementClass} krijgt u de tenminste de volgende producten:</label>
                    <ul>
                        {minimumAlarmProducts.map(product => <li key={`alarm-product-${product.id}`}>{product.name}</li>)}
                    </ul>
                </div>}
                <div className="form__group">
                    <FieldArray name="movementDetectionLocations" label="Positie Bewegingsmelders" component={renderPositionFields}/>
                </div>
                <div className="form__group">
                    <FieldArray name="magneticContactLocations" label="Positie Magneetcontacten" component={renderPositionFields}/>
                </div>
                <div className="form__group">
                    <FieldArray name="rollerDoorContactLocations" label="Positie Roldeurcontacten" component={renderPositionFields}/>
                </div>
                <div className="form__group">
                    <label>Positie Centrale</label>
                    <Field name="mainStationLocation" className="form-control" component={renderField} />
                    <Field name="mainStationLocation" component={renderError}/>
                </div>
            </>}

            <div className="form__group">
                <div className="form__input-wrapper">
                    <label>Opmerkingen</label>
                    <Field name="comments" component="textarea" className="form__input intake-textarea" placeholder=""/>
                </div>
            </div>

            {requirementClass > 2 && requirementClass <= 4 && <>
                <p className="mb-4">Voor klasse {requirementClass} is een bezoek op lokatie nodig.</p>
            </>}

            <button className="btn btn-success mt-3 mb-3" onClick={this.triggerSubmit}>Opslaan</button>
        </div>;
    }
}

const mapStateToProps = state => {
    const {identifier, canvas} = state.intake;

    return {identifier, canvas, formData: getFormValues('intake')(state)};
};

const mapDispatchToProps = dispatch => ({
    submitForm: form => dispatch(submit(form)),
});

export default reduxForm({
    form: 'intake',
    onSubmit: submitIntakeForm,
    initialValues: intakeInitialValues(),
    validate
})(connect(mapStateToProps, mapDispatchToProps)(IntakeForm));
