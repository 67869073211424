export function updateFacilityXLFormData(change, data) {
    change('company', data.company);
    change('street', data.street);
    change('housenr', data.housenr);
    change('zipcode', data.zipcode);
    change('city', data.city);
    change('contact', data.contact);
    change('initials', data.initials);
    change('lastname', data.lastname);
    change('phone', data.phone);
    change('mobilePhone', data.mobilePhone);
    change('email', data.email);

    change('coc', data.coc);
    change('vat', data.vat);
    change('iban', data.iban);

    change('sameAsContact', true);
    change('differentInvoiceAddress', data.differentInvoiceAddress);

    if (data.differentInvoiceAddress) {
        change('invoiceContact', data.contact);
        change('invoiceEmail', data.email);
        change('invoicePhone', data.phone);
        change('invoiceStreet', data.invoiceStreet);
        change('invoiceHousenr', data.invoiceHousenr);
        change('invoiceZipcode', data.invoiceZipcode);
        change('invoiceCity', data.invoiceCity);

    }

    change('projectContact', data.contact);
    change('projectEmail', data.email);
    change('projectPhone', data.phone);
    change('logo', data.logo);
    change('clientId', data.clientId);
    change('partnerId', data.partnerId);
}


