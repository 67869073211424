import React from 'react';
import PropTypes from 'prop-types';

class ReadMoreDescription extends React.Component {
    static propTypes = {
        description: PropTypes.string.isRequired,
        shortText: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            folded: true,
            loaded: false,
        };

        this.boxRef = React.createRef();
    }

    componentDidMount() {
        this.setState({loaded: true});
    }

    contentIsLonger = () => {
        const {current} = this.boxRef;

        return current && current.scrollHeight > current.clientHeight;
    }

    open = event => {
        event.preventDefault();

        this.setState({folded: false})
    }

    renderText = () => {
        const {description, shortText} = this.props;
        const {folded} = this.state;

        if (shortText && folded) {
            return shortText;
        }

        return description;
    }

    render() {
        const {shortText} = this.props;
        const {folded} = this.state;

        return <>
            <div ref={this.boxRef} className={`text-read-more ${folded ? 'folded' : 'opened'}`}>
                <p className="show-newlines" dangerouslySetInnerHTML={{ __html: this.renderText() }} />
            </div>
            {folded && (shortText || this.contentIsLonger()) && <a className="text-align-right read-more-anchor accent block" href="#" onClick={this.open}>Lees Meer...</a>}
        </>;
    }
}

export default ReadMoreDescription;
