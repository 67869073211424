export const validate = values => {
    const errors = {};

    if (!values.manager) {
        errors.manager = 'Dit veld is verplicht';
    }

    if (!values.installationCode) {
        errors.installationCode = 'Dit veld is verplicht';
    }

    if (!values.endDate) {
        errors.endDate = 'Dit veld is verplicht';
    }

    return errors;
}
