import {updateFacilityXlCustomer, updateRelatedProductSettings} from '../actions';

export function fetchAvailableRelatedProductSettings(identifier) {
    return dispatch => {
        return fetch(`/api/related-product-settings/${identifier}`)
            .then((response) => response.json())
            .then((responseData) => {
                dispatch(updateRelatedProductSettings(responseData));

                return responseData;
            })
            .catch(error => console.warn(error));
    }
}

export function fetchFacilityXLCustomerData(relationId) {
    return dispatch => {
        return fetch(`/api/facility/customer/${relationId}/data`)
            .then((response) => response.json())
            .then((responseData) => {
                dispatch(updateFacilityXlCustomer(responseData));

                return responseData;
            })
            .catch(error => console.warn(error));
    }
}
