import "core-js/stable";
import "regenerator-runtime/runtime";

//require('../../css/app.scss');
require('../../css/template/stylesheet.scss');

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import intakeStore from "../intake/store/store";
import submitOrder from "./submitOrder";
import './../signature';
import InstallationFormWizard from './installation/InstallationFormWizard';
import GenericOrderProcessFormWizard from "./genericOrderProcess/GenericOrderProcessFormWizard";
import IntakeForm from '../intake/components/camera/intake';
import AlarmIntakeForm from '../intake/components/alarm/intake';

import ReadMoreDescription from '../components/ReadMoreDescription';
import ExtraInfoForm from '../security-info/components/extraInfoForm';
import {submitIntakeExtraInfoForm} from '../intake/helpers/submit';

const installationOrderElement = document.getElementById("installationOrdersRoot");
if (installationOrderElement) {
    ReactDOM.render(
        <Provider store={store}>
            <InstallationFormWizard onSubmit={submitOrder} />
        </Provider>,
        installationOrderElement
    );
}

const genericOrderProcess = document.getElementById("genericOrderProcess");
if (genericOrderProcess) {
    const {duration, slug, relationId} = genericOrderProcess.dataset;

    ReactDOM.render(
        <Provider store={store}>
            <GenericOrderProcessFormWizard onSubmit={submitOrder} steps={JSON.parse(genericOrderProcess.dataset.steps)} contractDuration={duration} identifier={slug} relationId={relationId} />
        </Provider>,
        genericOrderProcess
    );
}

const intakeForm = document.getElementById("intakeForm");
if (intakeForm) {
    const {uuid} = intakeForm.dataset;

    ReactDOM.render(
        <Provider store={intakeStore}>
            <IntakeForm intakeId={uuid} />
        </Provider>,
        intakeForm
    );
}

const alarmIntakeForm = document.getElementById("alarmIntakeForm");
if (alarmIntakeForm) {
    const {uuid} = alarmIntakeForm.dataset;

    ReactDOM.render(
        <Provider store={intakeStore}>
            <AlarmIntakeForm intakeId={uuid} />
        </Provider>,
        alarmIntakeForm
    );
}

const extraIntakeInfo = document.getElementById("intakeExtraInfoForm");
if (extraIntakeInfo) {
    const {uuid} = extraIntakeInfo.dataset;

    ReactDOM.render(
        <Provider store={intakeStore}>
            <ExtraInfoForm intakeId={uuid} onSubmit={submitIntakeExtraInfoForm} />
        </Provider>,
        extraIntakeInfo
    );
}

window.addEventListener("submit", function(){
    window.scroll({
        top: 600,
        left: 0,
        behavior: 'smooth'
    });
});

const iconHolder = document.getElementById("icon-holder");
if(iconHolder) {
    const headphoneIcon = document.getElementById("headphone-image");
    moveImage(headphoneIcon, 1, 250,'positive', 0.12,  0.17);

    const tvIcon = document.getElementById("tv-image");
    moveImage(tvIcon, 1, 233, 'positive', 0.17,  -0.10);

    const phoneIcon = document.getElementById("phone-image");
    moveImage(phoneIcon, 1, 170, 'positive', -0.1,  -0.15);

    const cloudIcon = document.getElementById("cloud-image");
    moveImage(cloudIcon, 1, 270, 'positive', -0,  -0.2);

    const telIcon = document.getElementById("tel-image");
    moveImage(telIcon, 1, 270, 'positive', -0.25,  0.2);

    const wifiIcon = document.getElementById("wifi-image");
    moveImage(wifiIcon, 1, 270, 'positive', 0.35,  -0.2);
}

function moveImage(image, loop, maxLoops, direction, x , y, timout = 33) {
    setTimeout(function() {
        let timout = 33;

        if(loop === maxLoops) {
            direction = 'negative';
        } else if(loop === 0) {
            direction = 'positive';
        }

        if(direction === 'positive') {
            loop++;
        } else {
            loop--;
        }

        if(loop > (maxLoops * 0.15) && loop < (maxLoops * 0.85)) {
            timout = 20;
        }

        image.style.transform = "translate3d(" + loop * x + "px, " + loop * y + "px, 0)";
        moveImage(image, loop, maxLoops, direction, x , y, timout);
    }, timout)
}

const readmoreDescriptions = document.getElementsByClassName("read-more-text");
if (readmoreDescriptions) {

    for(const element of readmoreDescriptions)
    {
        const {shortText} = element.dataset;

        ReactDOM.render(
            <ReadMoreDescription description={element.innerHTML} shortText={shortText} />,
            element
        );
    }

}
