import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    fetchAvailableProducts,
    fetchAvailableProductsForPackage,
    getNextPage,
    getPreviousPage
} from "../helpers";
import RemoteSubmitButton from "./../RemoteSubmitButton";
import PriceList from "../PriceList";
import GenericOrderProcessStep from "./GenericOrderProcessStep";
import GenericStepList from "./GenericStepList";
import {getQueryParameter} from '../../helper/queryString';
import {change, getFormValues, registerField} from 'redux-form';
import {connect} from 'react-redux';
import {updateContractDuration} from '../store/actions';
import {fetchAvailableRelatedProductSettings, fetchFacilityXLCustomerData} from '../store/api/api';
import {updateFacilityXLFormData} from '../helpers/facilityXL';

class GenericOrderProcessFormWizard extends Component {
    constructor(props) {
        super(props);

        const {steps} = this.props;

        this.state = {
            page: steps[0],
            products: [],
            steps: steps,
        };

    }

    componentDidMount() {
        const packageId = getQueryParameter('identifier');
        const {contractDuration, identifier, relationId} = this.props;

        this.props.updateContractDuration(contractDuration);
        this.props.fetchAvailableRelatedProductSettings(identifier);
        this.props.change('contractDuration', this.props.contractDuration);
        this.props.registerField('orderProcess');
        this.props.change('orderProcess', identifier);

        if (packageId) {
            this.props.registerField('packageId');
            this.props.change('packageId', packageId);

            fetchAvailableProductsForPackage(packageId).then(response => this.setState({products: response}));
        } else {
            fetchAvailableProducts().then(response => this.setState({products: response}));
        }

        if (relationId) {
            this.props.fetchFacilityXLCustomerData(relationId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {products} = this.state;
        const {formData, productSettings, facilityXLCustomer} = this.props;

        if (products !== prevState.products) {
            products.forEach(product => {
                if (product.amount_included_in_package > 0) {
                    this.props.registerField(`product_${product.product_id}`);
                    this.props.change(`product_${product.product_id}`, product.amount_included_in_package);
                }
            });
        }

        if (productSettings && formData !== prevProps.formData) {
            this.checkRelatedProductSettings(formData, prevProps.formData)
        }

        if (facilityXLCustomer && facilityXLCustomer !== prevProps.facilityXLCustomer) {
            this.props.registerField('clientId');
            this.props.registerField('partnerId');
            this.props.registerField('logo');
            updateFacilityXLFormData(this.props.change, facilityXLCustomer);
        }
    }

    nextPage = () => {
        const {page, steps} = this.state;
        const nextPage = getNextPage(page, steps);

        this.setState({page: nextPage});
    }

    previousPage = () => {
        const {page, steps} = this.state;
        const nextPage = getPreviousPage(page, steps);

        this.setState({page: nextPage});
    }

    backToPage = newPage => {
        const {page, steps} = this.state;
        if (steps.indexOf(page) > steps.indexOf(newPage)) {
            this.setState({page: newPage});
        }
    }

    isLastStep = page => {
        const {steps} = this.state;

        return steps.indexOf(page) === steps.length - 1
    }

    getFieldValue = field => {
        const {formData} = this.props;

        return formData[field];
    }

    registerFieldIfNotExists = field => {
        const {formData} = this.props;

        if (formData[field] === undefined) {
            this.props.registerField(field);
        }
    }

    /**
     * Calculate related product value based on the difference between current value and previous value
     */
    calculateRelatedProductValue = (setting, currentValue, prevValue = 0, currentRelatedProductValue = 0) => {
        const {increment, value} = setting;

        if (increment) {
            const difference = currentValue - prevValue;
            return difference + parseInt(currentRelatedProductValue);
        }

        return value;
    }

    checkRelatedProductSettings = (data, prevData) => {
        const {productSettings} = this.props;

        productSettings.forEach(setting => {
            let productKey = `product_${setting.product_id}`;
            let relatedProductKey = `product_${setting.related_product_id}`;

            if (data[productKey] !== prevData[productKey]) {
                this.registerFieldIfNotExists(relatedProductKey);

                // If increment is enabled, we should always change values, ignore it if a non-incremental product gets disabled
                if (setting.increment || data[productKey] > 0) {
                    const currentRelatedProductValue = this.getFieldValue(relatedProductKey) || 0;
                    const newValue = this.calculateRelatedProductValue(setting, data[productKey], prevData[productKey], currentRelatedProductValue);
                    this.props.change(relatedProductKey, newValue);
                }
            }
        });
    };

    renderStep(step, onSubmit) {
        const {products, page, steps} = this.state;

        if (page.name !== step.name) {
            return null;
        }

        let nextAction = this.nextPage;
        let previousAction = null;

        if (steps.indexOf(page) > 0) {
            previousAction = this.previousPage;
        }

        return <GenericOrderProcessStep key={step.id} products={products} step={step} onSubmit={this.isLastStep(page) ? onSubmit : nextAction} previousAction={previousAction} lastStep={this.isLastStep(page)}/>;
    }

    render() {
        const {onSubmit, steps} = this.props;
        const {page} = this.state;

        return (
            <>
                <GenericStepList backToPage={this.backToPage} currentPage={page} steps={steps}/>
                <section className="specifications">
                    <div className="container container--large">
                        <div className="card">

                            {steps.map(step => this.renderStep(step, onSubmit))}
                        </div>
                        <div className="card">
                            <h2 className="h2 card__title">Mijn Bestelling</h2>
                            <div className="specifications__divider" />

                            <PriceList/>

                            <RemoteSubmitButton currentPage={page} steps={steps}/>

                        </div>
                    </div>
                </section>
            </>
        );
    }
}

GenericOrderProcessFormWizard.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
    fetchAvailableRelatedProductSettings: PropTypes.func.isRequired,
    identifier: PropTypes.string.isRequired,
    formData: PropTypes.object,
    fetchFacilityXLCustomerData: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const {productSettings, facilityXLCustomer} = state.orderProcess;

    return {productSettings, facilityXLCustomer, formData: getFormValues('wizard')(state)};
};

const mapDispatchToProps = dispatch => ({
    change: (field, value) => dispatch(change('wizard', field, value)),
    registerField: field => dispatch(registerField('wizard', field, 'Field')),
    updateContractDuration: duration => dispatch(updateContractDuration(duration)),
    fetchAvailableRelatedProductSettings: identifier => dispatch(fetchAvailableRelatedProductSettings(identifier)),
    fetchFacilityXLCustomerData: relationId => dispatch(fetchFacilityXLCustomerData(relationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericOrderProcessFormWizard);
