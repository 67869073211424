import React from 'react';
import {getFormValues, reduxForm} from 'redux-form';
import validate from '../validate';
import connect from "react-redux/es/connect/connect";
import {renderGender} from '../../helper/text';

class SimpleConfirmation extends React.Component {
    render() {
        const {formData} = this.props;

        return <>
            <h2 className="h2 card__title">Bevestiging</h2>

            <div className="confirmation__blocks">
                <div className="confirmation__block">
                    <h3 className="h3">Persoonlijke informatie</h3>

                    <p className="confirmation__description">{renderGender(formData.gender)} {formData.initials} {formData.lastname}</p>
                    <p className="confirmation__description">{formData.company}</p>
                    <p className="confirmation__description">{formData.contact}</p>
                    <p className="confirmation__description"><b>T</b> {formData.phone}</p>
                    <p className="confirmation__description"><b>M</b> {formData.email}</p>

                </div>

                <div className="confirmation__block" />
            </div>
        </>;
    }
}

SimpleConfirmation = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(SimpleConfirmation);

SimpleConfirmation = connect(
    state => ({
        formData: getFormValues('wizard')(state)
    }),
)(SimpleConfirmation);

export default SimpleConfirmation;
