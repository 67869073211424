import {INTAKE_UPDATE_CANVAS_REF, INTAKE_UPDATE_INTAKE_ID} from './actionTypes';

export const updateIntakeId = identifier => ({
    type: INTAKE_UPDATE_INTAKE_ID,
    payload: {identifier},
});

export const updateCanvasRef = canvas => ({
    type: INTAKE_UPDATE_CANVAS_REF,
    payload: {canvas},
});
