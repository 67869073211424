import moment from 'moment';

export default function intakeInitialValues() {
    return {
        intakeImages: [],
        cameras: 1,
        nvrAmount: 1,
        nvrLocation: '',
        ceilingType: '',
        cameraLocations: [''],
        installationDate: moment().toDate(),
        cameraTypes: [{}],
        needVgaCable: false,
        needScreen: false,
        vgaCable: 0,
        screenLocation: '',
        insuranceRequirement: '0',
        requirementClass: 0,
        movementDetectionLocations: [''],
        magneticContactLocations: [''],
        rollerDoorContactLocations: [''],
        mainStationLocation: '',
        isRenovation: '0',
    }
}
