import {Field} from 'redux-form';
import renderField from '../../../components/blocks/renderField';
import React from 'react';
import renderError from '../../../components/blocks/renderError';

const renderCameraTypeFields = ({fields, meta: {error}}) => (
    <div className="w-100">
        {fields.map((camera, index) => (
            <div className="relative phone-field-container camera-type-container" key={index}>
                <h5 className="relative flex items-center camera-type-header" style={{marginBottom: '10px'}}>
                    <span>Camera Type</span>
                    <div className="number-btn-container">
                        {index === 0 && <button type="button"  className="accent pointer" onClick={() => fields.push({})}>
                            <i className="fas fa-plus" />
                        </button>}
                        {index > 0 && <button type="button" className="warning pointer" onClick={() => fields.remove(index)}>
                            <i className="fas fa-times" />
                        </button>}
                    </div>
                </h5>

                <div className="m-0 form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-gender-men" name={`${camera}.type`} value="bullet" />
                        <div className="form__radio" />
                        <label htmlFor="radio-gender-men" className="form__label">Bullet Camera</label>
                    </div>

                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-gender-women" name={`${camera}.type`} value="turret" />
                        <div className="form__radio" />
                        <label htmlFor="radio-gender-women" className="form__label">Turret Camera</label>
                    </div>

                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-gender-none" name={`${camera}.type`} value="dome" />
                        <div className="form__radio" />
                        <label htmlFor="radio-gender-none" className="form__label">Dome Camera</label>
                    </div>
                </div>
                <Field name={`${camera}.type`} component={renderError}/>
                <br/>
                <div className="form__group m-0">
                    <Field name={`${camera}.amount`} label="Aantal" className="form-control" type="number" component={renderField} />
                    <Field name={`${camera}.amount`} component={renderError}/>
                </div>

                <div className="form__group m-0">
                    <div className="form__input-wrapper">
                        <div className="form__checkbox-wrapper w-100">
                            <Field name={`${camera}.clamp`} id={`clamp${camera}`} component="input" type="checkbox" value="yes"/>
                            <div className="form__checkbox" />
                            <label htmlFor={`clamp${camera}`} className="form__label">Beugel benodigd</label>
                        </div>
                    </div>
                </div>

            </div>

        ))}
        {error && <li className="error">{error}</li>}
    </div>
);

export default renderCameraTypeFields;
