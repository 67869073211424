import moment from 'moment';

export default function intakeAdditionalInitialValues() {
    return {
        intakeImages: [],
        manager: '',
        installationCode: '',
        comments: '',
        endDate: moment().toDate(),
    }
}
