import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Field, getFormValues, reduxForm, submit} from 'redux-form';
import renderField from '../../../components/blocks/renderField';
import FieldArray from 'redux-form/lib/FieldArray';
import renderPositionFields from '../fields/renderPositionFields';

import {validate} from '../../helpers/validate';
import renderError from '../../../components/blocks/renderError';
import {submitIntakeForm} from '../../helpers/submit';
import renderCameraTypeFields from '../fields/renderCameraTypeFields';
import intakeInitialValues from '../../helpers/intakeInitialValues';

class IntakeForm extends React.Component {
    static propTypes = {
        submitForm: PropTypes.func.isRequired,
    };

    triggerSubmit = event => {
        event.preventDefault();

        if (confirm("Wilt u het intake formulier opslaan?")) {
            this.props.submitForm('intake');
        } else {
            return false;
        }
    }

    render() {
        const {formData: {needScreen, needVgaCable}} = this.props;

        return <div>

            <div className="form__group">
                <FieldArray name="cameraTypes" component={renderCameraTypeFields}/>
            </div>

            <div className="form__group">
                <label>NVR (Aantal kanalen)</label>
                <Field name="nvrAmount" className="form-control" type="number" component={renderField} />
                <Field name="nvrAmount" component={renderError}/>
            </div>
            <div className="form__group">
                <label>Positie NVR</label>
                <Field name="nvrLocation" className="form-control" component={renderField} />
                <Field name="nvrLocation" component={renderError}/>
            </div>
            <div className="form__group">
                <label>Plafond Type</label>
                <Field name="ceilingType" className="form-control" component={renderField} />
                <Field name="ceilingType" component={renderError}/>
            </div>

            <div className="form__group m-0">
                <div className="form__input-wrapper m-0">
                    <div className="form__checkbox-wrapper w-100">
                        <Field name="needScreen" id="needScreen" component="input" type="checkbox" value="yes"/>
                        <div className="form__checkbox" />
                        <label htmlFor="needScreen" className="form__label">TV scherm nodig</label>
                    </div>
                </div>
                {needScreen && <div className="form__input-wrapper">
                    <div className="form__checkbox-wrapper w-100 m-0">
                        <Field name="needVgaCable" id="needVgaCable" component="input" type="checkbox" value="yes"/>
                        <div className="form__checkbox" />
                        <label htmlFor="needVgaCable" className="form__label">VGA Kabel voor scherm nodig</label>
                    </div>
                </div>}
            </div>

            <div className="form__group">

            </div>

            <div className="form__group">
                <label className="mb-2">Is er een verzekeringseis?</label>
                <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-insurance-yes" name="insuranceRequirement" value="1" />
                        <div className="form__radio" />
                        <label htmlFor="radio-insurance-yes" className="form__label">Ja</label>
                    </div>
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-insurance-no" name="insuranceRequirement" value="0" />
                        <div className="form__radio" />
                        <label htmlFor="radio-insurance-no" className="form__label">Nee</label>
                    </div>
                </div>
            </div>

            {needScreen && <div className="form__group">
                {needVgaCable && <>
                    <label>Meters VGA Kabel</label>
                    <Field name="vgaCable" className="form-control" type="number" component={renderField} />
                    <Field name="vgaCable" component={renderError}/>
                </>}
                <label>Lokatie TV Scherm</label>
                <Field name="screenLocation" className="form-control" component={renderField} />
                <Field name="screenLocation" component={renderError}/>
            </div>}

            <div className="form__group">
                <label>Camera Lokaties</label>
                <FieldArray name="cameraLocations" component={renderPositionFields}/>
            </div>

            <div className="form__group">
                <label className="mb-2">Is er een verbouwing?</label>
                <div className="form__input-wrapper form__input-wrapper--radio form__input-wrapper--force-column">
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-renovation-yes" name="isRenovation" value="1" />
                        <div className="form__radio" />
                        <label htmlFor="radio-renovation-yes" className="form__label">Ja</label>
                    </div>
                    <div className="form__radio-wrapper">
                        <Field component="input" type="radio" id="radio-renovation-no" name="isRenovation" value="0" />
                        <div className="form__radio" />
                        <label htmlFor="radio-renovation-no" className="form__label">Nee</label>
                    </div>
                </div>
            </div>

            <div className="form__group">
                <div className="form__input-wrapper">
                    <label>Opmerkingen</label>
                    <Field name="comments" component="textarea" className="form__input intake-textarea" placeholder=""/>
                </div>
            </div>

            <button className="btn btn-success mt-3 mb-3" onClick={this.triggerSubmit}>Opslaan</button>
        </div>;
    }
}

const mapStateToProps = state => {
    const {identifier, canvas} = state.intake;

    return {identifier, canvas, formData: getFormValues('intake')(state)};
};

const mapDispatchToProps = dispatch => ({
    submitForm: form => dispatch(submit(form)),
});

export default reduxForm({
    form: 'intake',
    onSubmit: submitIntakeForm,
    initialValues: intakeInitialValues(),
    validate
})(connect(mapStateToProps, mapDispatchToProps)(IntakeForm));
