import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import renderField from '../../components/blocks/renderField';

class MobilePhoneInput extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired
    };

    render() {
        const {fields} = this.props;

        return fields.map((mobile, index) => <div key={`Number${index}`} className="flex between w-100 mobile-number-field-container">
            <Field name={`${mobile}.number`} component={renderField} label="Telefoonnummer" />
            <Field name={`${mobile}.provider`} component={renderField} label="Provider" />
            <Field name={`${mobile}.reference`} component={renderField} label="Klantnummer" />
        </div>);
    }
}

export default MobilePhoneInput;
