import React from 'react';
const GenericStepList = props => {
    const {backToPage, currentPage, steps} = props;
    const activeIndex = steps.reduce((carry, step, index) => {
        if (currentPage.name === step.name) {
            return index;
        }

        return carry;
    }, 0)

    const activeStep = steps[activeIndex] || {};

    return <section className="progressbar__wrapper">
        <div className="container container--large">
            <div className="progressbar">
                <div className="bar" />
                <div className="bar progress progress--three-third progress--full" />
                <div className="bullets">
                    {steps.map((step, index) => {
                        return <div key={`genericstep-${index}`} className="bullet_container" onClick={() => backToPage(step)}>
                            <div className="bullet-label">{step.name}</div>
                            <div className={`bullet ${(index <= activeIndex ? ' active' : '')}`}>
                                <div className="bullet-inner">{index + 1}</div>
                            </div>
                        </div>;
                    })}
                </div>
            </div>
            {activeStep && <div className="bullet-header center card">
                <h3 className="h3 m-0">{activeStep.name}</h3>
            </div>}
        </div>
    </section>;
};

export default GenericStepList;
